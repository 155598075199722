import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_BYRAH_API_KEY,
  authDomain: process.env.REACT_APP_BYRAH_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_BYRAH_PROJECT_ID,
  storageBucket: process.env.REACT_APP_BYRAH_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_BYRAH_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_BYRAH_APP_ID,
  measurementId: process.env.REACT_APP_BYRAH_MEASUREMENT_ID,
};

const app = initializeApp(firebaseConfig);

let analytics;
if (typeof window !== "undefined") {
  analytics = getAnalytics(app); // Only initialize analytics if running in a browser
}

export const auth = getAuth(app);
export const db = getFirestore(app);
