import React, { useEffect, useState } from "react";
import { db, auth } from "../firebase";
import { collection, query, where, getDocs } from "firebase/firestore";
import { signOut } from "firebase/auth";
import { useNavigate } from "react-router-dom";

interface Links {
  facebook: string;
  instagram: string;
  twitter: string;
  linkedin: string;
}

export default function Profile() {
  const [links, setLinks] = useState<Links | null>(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchLinks = async () => {
      const user = auth.currentUser;
      if (user) {
        const q = query(
          collection(db, "socialLinks"),
          where("userId", "==", user.uid)
        );
        try {
          const querySnapshot = await getDocs(q);
          if (!querySnapshot.empty) {
            const data = querySnapshot.docs.map((doc) =>
              doc.data()
            )[0] as Links;
            setLinks(data);
          } else {
            navigate("/add-links");
          }
        } catch (error) {
          console.error("Error fetching links from Firestore:", error);
        }
      } else {
        navigate("/");
      }
      setLoading(false);
    };

    fetchLinks();
  }, [navigate]);

  const handleLogout = async () => {
    try {
      await signOut(auth);
      navigate("/");
    } catch (error) {
      console.error("Error signing out:", error);
    }
  };

  if (loading) return <p className="text-center text-lg">Loading...</p>;

  if (!links) return null;

  return (
    <div className="flex items-center justify-center min-h-screen bg-gradient-to-r from-indigo-500 via-purple-500 to-pink-500">
      <div className="bg-white p-8 rounded-lg shadow-lg w-full max-w-md">
        <div className="flex justify-end mb-4">
          <button
            onClick={handleLogout}
            className="py-1 px-4 bg-indigo-600 text-white rounded-md hover:bg-indigo-700 transition-colors duration-300"
          >
            Sign Out
          </button>
        </div>
        <h2 className="text-2xl font-bold text-center mb-6 text-gray-800">
          Your Social Media Links
        </h2>
        <div className="space-y-4 text-center">
          <a
            href={links.facebook}
            target="_blank"
            rel="noopener noreferrer"
            className="text-indigo-600 hover:text-indigo-400 transition duration-200"
          >
            Facebook
          </a>
          <br />
          <a
            href={links.instagram}
            target="_blank"
            rel="noopener noreferrer"
            className="text-indigo-600 hover:text-indigo-400 transition duration-200"
          >
            Instagram
          </a>
          <br />
          <a
            href={links.twitter}
            target="_blank"
            rel="noopener noreferrer"
            className="text-indigo-600 hover:text-indigo-400 transition duration-200"
          >
            Twitter
          </a>
          <br />
          <a
            href={links.linkedin}
            target="_blank"
            rel="noopener noreferrer"
            className="text-indigo-600 hover:text-indigo-400 transition duration-200"
          >
            LinkedIn
          </a>
          <br />
        </div>
      </div>
    </div>
  );
}
