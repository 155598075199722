// src/App.tsx
import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { onAuthStateChanged } from "firebase/auth";
import { auth } from "./firebase"; // Import your firebase config
import Auth from "./components/Auth";
import SocialLinksForm from "./components/SocialLinksForm";
import Profile from "./components/Profile";

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setIsAuthenticated(!!user);

      setLoading(false);
    });

    // Cleanup the subscription
    return () => unsubscribe();
  }, []);

  if (loading) {
    return <p>Loading...</p>;
  }

  return (
    <Router>
      <Routes>
        {!isAuthenticated ? (
          <Route path="/" element={<Auth />} />
        ) : (
          <>
            <Route path="/profile" element={<Profile />} />
            <Route path="/add-links" element={<SocialLinksForm />} />
          </>
        )}
      </Routes>
    </Router>
  );
}

export default App;
