import React, { useState } from "react";
import { db } from "../firebase";
import { addDoc, collection } from "firebase/firestore";
import { auth } from "../firebase";
import { useNavigate } from "react-router-dom";
import { signOut } from "firebase/auth";

export default function SocialLinksForm() {
  const [links, setLinks] = useState({
    facebook: "",
    instagram: "",
    twitter: "",
    linkedin: "",
  });

  const navigate = useNavigate();

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const user = auth.currentUser;
    if (user) {
      await addDoc(collection(db, "socialLinks"), {
        userId: user.uid,
        facebook: links.facebook,
        instagram: links.instagram,
        twitter: links.twitter,
        linkedin: links.linkedin,
      });
      navigate("/profile");
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setLinks({
      ...links,
      [e.target.name]: e.target.value,
    });
  };

  const handleLogout = async () => {
    try {
      await signOut(auth);
      navigate("/");
    } catch (error) {
      console.error("Error signing out:", error);
    }
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-gradient-to-r from-indigo-500 via-purple-500 to-pink-500">
      <div className="bg-white p-8 rounded-lg shadow-lg w-full max-w-md">
        <div className="flex justify-end mb-4">
          <button
            onClick={handleLogout}
            className="py-1 px-4 bg-indigo-600 text-white rounded-md hover:bg-indigo-700 transition-colors duration-300"
          >
            Sign Out
          </button>
        </div>
        <h2 className="text-2xl font-bold text-center mb-6 text-gray-800">
          Add Your Social Media Links
        </h2>
        <form onSubmit={handleSubmit} className="space-y-4">
          <input
            type="text"
            name="facebook"
            placeholder="Facebook URL"
            value={links.facebook}
            onChange={handleChange}
            className="w-full p-2 border rounded"
          />
          <input
            type="text"
            name="instagram"
            placeholder="Instagram URL"
            value={links.instagram}
            onChange={handleChange}
            className="w-full p-2 border rounded"
          />
          <input
            type="text"
            name="twitter"
            placeholder="Twitter URL"
            value={links.twitter}
            onChange={handleChange}
            className="w-full p-2 border rounded"
          />
          <input
            type="text"
            name="linkedin"
            placeholder="LinkedIn URL"
            value={links.linkedin}
            onChange={handleChange}
            className="w-full p-2 border rounded"
          />
          <button
            type="submit"
            className="w-full py-2 bg-indigo-600 text-white rounded-md hover:bg-indigo-700 transition-colors duration-300"
          >
            Save Links
          </button>
        </form>
      </div>
    </div>
  );
}
